import React, { PureComponent, CSSProperties } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Theme from '../../theme'
import { ListItem } from '../temporaryDrawer'

const MenuItemWrapper = styled.div`
    display: inline-block;
    padding: 10px;

    &:hover {
        display: block;
    }
`

interface SubItemWrapperProps extends CSSProperties {
    isHovered: boolean,
    droppedBackgroundColor?: string,
    linkColor?: string,
    droppedLinkColor?: string
}

const SubItemWrapper = styled.div<SubItemWrapperProps>`
    display: ${props => props.isHovered ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '2px'};
    box-shadow: ${props => props.boxShadow ? props.boxShadow : '0px 0px 10px -4px black'};
    min-width: ${props => props.minWidth ? props.minWidth : 'unset'};
    background-color: white;

    > div:hover {
        background-color: ${props => props.droppedBackgroundColor ? props.droppedBackgroundColor : '#EAEAEA'};
        
        > a {
            color: ${props => props.droppedLinkColor ? props.droppedLinkColor : Theme.palette.primary.dark} !important;
        }
    }

    a {
        color: ${props => props.linkColor ? props.linkColor : 'black'};
    }
`

const StyledLink = styled(Link)`
    color: white;
    letter-spacing: 1px;
`

interface MenuItemProps {
    id: string,
    name: string,
    href: string,
    shouldCollapse?: boolean,
    nestedItems?: ListItem[]
}

interface MenuItemState {
    isHovered: boolean
}

export default class MenuItem extends PureComponent<MenuItemProps, MenuItemState> {
    constructor(props: MenuItemProps) {
        super(props)

        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        
        this.state = {
            isHovered: false
        }
    }

    handleMouseEnter() {
        this.setState({
            isHovered: true
        })
    }

    handleMouseLeave() {
        this.setState({
            isHovered: false
        })
    }

    render() {
        return(
            <MenuItemWrapper 
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                id={this.props.id}
                className={this.props.nestedItems ? 'nested' : ''}>
                <StyledLink 
                    to={this.props.href}>
                    {this.props.name}
                </StyledLink>
                {
                    this.props.nestedItems ? (
                        <SubItemWrapper isHovered={this.state.isHovered}>
                            {
                                this.props.nestedItems.map(item => (
                                    <MenuItem 
                                        key={item.id}
                                        id={item.id} 
                                        name={item.name} 
                                        href={item.href} 
                                        shouldCollapse={item.shouldCollapse} 
                                        nestedItems={item.nestedItems}/>
                                ))
                            }
                        </SubItemWrapper>
                    ) : null
                }
            </MenuItemWrapper>
        )
    }
}