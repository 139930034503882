import React, { PureComponent, createRef, RefObject, ChangeEvent } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import moment, { Moment } from 'moment'
import Reaptcha from 'reaptcha'

import Modal from './modal'

import 'react-infinite-calendar/styles.css'

import Section, {Header} from './section/section'
import theme from '../theme'

const EmailMessage: React.SFC<{message: string}> = (props) => {
    const Wrapper = styled.div`
        max-width: 90vw;
        background: white;
        padding: 20px;
        border-radius: 2px;
        box-shadow: 0px 2px 10px -3px lightgray;
    `

    return(
        <Wrapper>
            <Typography variant="body2">
                {props.message}
            </Typography>
        </Wrapper>
    )
}

interface InquireServiceState {
    dateTimeOfInterest: string,
    name: string,
    nameHasError: boolean,
    email: string,
    emailHasError: boolean,
    phoneNumber: string,
    phoneNumberHasError: boolean,
    phoneNumberMask: string,
    message: string,
    messageHasError: boolean,
    isLoadingDates: boolean,
    unavailableDates: string[],
    isEmailInQueue: boolean,
    isEmailSent: boolean,
    didEmailSendSuccessfully: boolean,
    isRecaptchaLoaded: boolean,
    isRecaptchaVerified: boolean,
    recaptchaToken: string | null,
    textMask: string
}

class InquireService extends PureComponent<{}, InquireServiceState> {
    private recpatchaInstance: RefObject<Reaptcha>

    constructor(props: {}) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this)
        // this.getAvailableDays = this.getAvailableDays.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.dismissEmailMessage = this.dismissEmailMessage.bind(this)
        this.recaptchaVerifyCallback = this.recaptchaVerifyCallback.bind(this)

        this.recpatchaInstance = createRef<HTMLDivElement>()

        const today = new Date()
        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString()

        this.state = {
            dateTimeOfInterest: tomorrow,
            name: '',
            nameHasError: false,
            email: '',
            emailHasError: false,
            phoneNumber: '',
            phoneNumberHasError: false,
            phoneNumberMask: '(1  )   -    ',
            message: '',
            messageHasError: false,
            isLoadingDates: true,
            unavailableDates: [],
            isEmailInQueue: false,
            isEmailSent: false,
            didEmailSendSuccessfully: false,
            isRecaptchaLoaded: false,
            isRecaptchaVerified: false,
            recaptchaToken: null,
            textMask: '(1  )    -    '
        }
    }

    componentDidMount() {
        // this.getAvailableDays()
    }

    dismissEmailMessage() {
        this.setState({
            isEmailInQueue: false,
            isEmailSent: false
        })
    }

    handleChange = (event: ChangeEvent<HTMLInputElement>) : void => {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}HasError`]: false
        })
    }

    handleDateTimeChange = (momentDate: Moment) : void => {
        this.setState({
            dateTimeOfInterest: moment(momentDate).format('YYYY-MM-DD HH:mm')
        })
    }

    recaptchaVerifyCallback(token: string) : void {
        this.setState({
            isRecaptchaVerified: true,
            recaptchaToken: token,
            nameHasError: !this.state.name.length,
            phoneNumberHasError: !this.state.phoneNumber.length,
            emailHasError: !this.state.email.length,
            messageHasError: !this.state.message.length
        })
    }

    /**
     * Make a call to a google calendar that holds all the events for the venue.
     */
    // getAvailableDays() : void {
    //     axios.get('/api/google/google-calendar-events')
    //         .then(res => {
    //             // console.log("Unavailable Dates: ", res.data)
    //             if (res.data && Array.isArray(res.data) && res.data.length) {
    //                 this.setState({
    //                     unavailableDates: res.data,
    //                     isLoadingDates: false
    //                 })
    //             }
    //         })
    //         .catch(err => {
    //             // console.log("Google calendar events error: ", err)
    //             if (this.state.unavailableDates.length) {
    //                 this.setState({
    //                     unavailableDates: []
    //                 })
    //             }
    //         })
    // }

    sendEmail() {
        const hasName = this.state.name.length
        const hasPhoneNumber = this.state.phoneNumber.length
        const hasEmail = this.state.email.length
        const hasMessage = this.state.message.length

        if (!hasName || !hasPhoneNumber || !hasEmail || !hasMessage) {
            this.setState({
                nameHasError: !hasName,
                phoneNumberHasError: !hasPhoneNumber,
                emailHasError: !hasEmail,
                messageHasError: !hasMessage
            })
        } else {
            this.setState({
                isEmailInQueue: true
            })

            const requestConfig = {
                params: {
                    name: this.state.name,
                    email: this.state.email,
                    telephone: this.state.phoneNumber,
                    dateTime: encodeURI(moment(this.state.dateTimeOfInterest).format('MMMM Do YYYY, h:mm a')),
                    message: this.state.message,
                    token: this.state.recaptchaToken
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            }
          
            axios.get(window.location.origin+'/api/email', requestConfig)
                .then(emailRes => {
                    if (emailRes.data.success) {
                        const today = new Date()
                        const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toISOString()

                        this.setState({
                            isEmailSent: true,
                            isEmailInQueue: false,
                            didEmailSendSuccessfully: true,
                            isRecaptchaVerified: false,
                            dateTimeOfInterest: tomorrow,
                            name: '',
                            email: '',
                            phoneNumber: '',
                            message: ''
                        })

                        this.recpatchaInstance.current.reset()
                    }
                })
                .catch(err => {
                    // console.log('ERROR: ', err)
                    this.setState({
                        isEmailSent: true,
                        isEmailInQueue: false,
                        didEmailSendSuccessfully: false,
                        isRecaptchaVerified: false,
                    })

                    this.recpatchaInstance.current.reset()
                })
        }
    }

    render() {
        const disabledDates = this.state.unavailableDates.length ? [...this.state.unavailableDates.map(date => new Date(date))] : []

        let emailMessage = null
        let progressIndicator = null
    
        if (this.state.isEmailSent && !this.state.isEmailInQueue && this.state.didEmailSendSuccessfully) {
            // Email successfully sent
            emailMessage = <Modal handleClose={this.dismissEmailMessage}><EmailMessage message={`Sent! Thanks for inquiring our services, we'll be in touch soon!`}/></Modal> 
        } 
        else if (this.state.isEmailInQueue) {
            // Email is sending
            progressIndicator = <CircularProgress style={{color: theme.palette.secondary.dark, position: 'absolute', top: '50%', left: '50%'}}/>
        }
        else if (this.state.isEmailSent && !this.state.isEmailInQueue && !this.state.didEmailSendSuccessfully) {
            // Email failed to send
            emailMessage = <Modal handleClose={this.dismissEmailMessage}><EmailMessage message="Sorry, our system is having some minor issues, please try again later."/></Modal>
        }

        const doErrorsExist = this.state.nameHasError || this.state.phoneNumberHasError || this.state.emailHasError || this.state.messageHasError || !this.state.isRecaptchaVerified
    
        return(
            <Section flexDirection="column" maxWidth="90vw" margin="20px auto">
                <Header>Inquire About Services</Header>
                <Section width="100%" margin="0 auto" alignItems="baseline" justifyContent="center" flexWrap="wrap">
                    <Section flexDirection="column" margin="20px" padding="0 20px">
                        <Typography
                            variant="body1"
                            gutterBottom={true}>
                            Date
                        </Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                value={this.state.dateTimeOfInterest}
                                disablePast={true}
                                onChange={this.handleDateTimeChange}
                                shouldDisableDate={(date) => {
                                    let shouldDisable = false

                                    disabledDates.forEach(disabledDate => {
                                        if (moment.utc(disabledDate).format('MM-DD-YYYY') === moment.utc(date).format('MM-DD-YYYY')) {
                                            shouldDisable = true
                                        }
                                    })

                                    return shouldDisable
                                }}/>
                        </MuiPickersUtilsProvider>
                        <TextField 
                            id="contact-name" 
                            label="Name"
                            error={this.state.nameHasError}
                            helperText="Required"
                            onChange={this.handleChange}
                            value={this.state.name}
                            required={true}
                            inputProps={{
                                name: 'name'
                            }}
                            style={{marginBottom: '20px'}}/>
                        <TextField 
                            id="contact-phone-number"
                            error={this.state.phoneNumberHasError}
                            label="Phone Number"
                            helperText="Required"
                            onChange={this.handleChange}
                            value={this.state.phoneNumber}
                            placeholder="123 456 7890"
                            type="tel"
                            required={true}
                            inputProps={{
                                maxLength: 10,
                                name: 'phoneNumber',
                                pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}'
                            }}
                            style={{marginBottom: '20px'}}/>
                        <TextField 
                            id="contact-email" 
                            error={this.state.emailHasError}
                            label="Email" 
                            helperText="Required" 
                            onChange={this.handleChange}
                            value={this.state.email}
                            type="email"
                            required={true}
                            style={{marginBottom: '20px'}}
                            inputProps={{
                                name: 'email'
                            }} />
                        <TextField 
                            id="contact-message" 
                            error={this.state.messageHasError}
                            label="Message" 
                            multiline={true}
                            onChange={this.handleChange}
                            value={this.state.message}
                            helperText="Required" 
                            required={true}
                            style={{marginBottom: '20px'}} 
                            inputProps={{
                                name: 'message'
                            }}
                            />
                        <Reaptcha 
                            ref={this.recpatchaInstance}
                            sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY} 
                            onVerify={this.recaptchaVerifyCallback} />
                        <Button 
                            id="contact-form-submit"
                            style={{
                                marginTop: 40, 
                                background: 'linear-gradient(to right, #c84444 1%,#ff5959 100%)',
                                color: 'white',
                                alignSelf: 'flex-end',
                                boxShadow: '0px 4px 0 0 #c84444',
                                opacity: doErrorsExist || this.state.isEmailInQueue ? 0.7 : 1.0
                            }} 
                            disabled={doErrorsExist || this.state.isEmailInQueue}
                            variant="contained" 
                            onClick={this.sendEmail}>
                            SEND MESSAGE
                        </Button>
                    </Section>
                </Section>
                {progressIndicator}
                {emailMessage}
            </Section>
        )  
    }
}

export default InquireService