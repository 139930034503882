export const Business = Object.freeze({
    name: "The Soundstage at Strange Land",
    address: "201 NW Victoria Dr",
    city: "Lees Summit",
    state: "MO",
    zip: "64086",
    phoneNumber: "(816) 434-7274",
    email: "info@thesoundstageatstrangeland.com",
    url: "https://www.thesoundstageatstrangeland.com",
    facebook: "https://www.facebook.com/strangelandsoundstage/",
    twitter: "https://twitter.com/thesoundstagesl",
    instagram: "https://www.instagram.com/thesoundstageatstrangeland/",
    youtube: "https://www.youtube.com/channel/UCQwrOgib0SFfC3ZRExCKKBw"
})